$(function () {
  var $title = $(".search-user__category-box .search-user__title");
  var $box = $(".search-user__category-box");
  $title.on("click", function () {
    if (!$box.hasClass("is-opened")) {
      $box.addClass("is-opened");
    } else {
      $box.removeClass("is-opened");
    }
  });
});
