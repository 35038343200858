import { ImagesUploadTemplate } from "./images_upload_template";

class ProfileUserImagesUpload extends ImagesUploadTemplate {
  constructor(form, inputField, previewDeleteIcon, previewItem) {
    super(form, inputField, previewDeleteIcon, previewItem);
  }

  run() {
    this.#checkProfileEdit();
    super.run();
  }

  appendToPreview(form, result, fileInputIdNumber) {
    let parent = $("<li>", {
      class: "form-table-images__item form-table-images__item-prev"
    }).appendTo(form.find(".form-table-images-prev"));
    $("<img>", {
      src: result,
      id: "preview_image"
    }).appendTo(parent.find(".form-table-images__item-prev").prevObject);
    $("<button>", {
      value: "画像を削除する",
      class: "form-table-images__delete",
      id: fileInputIdNumber
    }).appendTo(parent.find(".form-table-images__item-prev").prevObject);
  }

  #checkProfileEdit() {
    // 更新失敗時、_destroyがtrueもしくはバリデーションエラーのフィールドを処理
    if ($(`.${this.previewItem}`).length) {
      $(`[id^='${this.inputField}']`).each(function (_index, element) {
        let destroyFlag = $(element).next().val();
        let imageCache = $(element).prev().val();
        if (destroyFlag == "true") {
          $(element).parent().hide();
          $("#delete_cocoon_image_field").append($(element).parent());
        } else if ($(element).siblings("a.dynamic").length && !imageCache) {
          $(element).siblings("a.dynamic").trigger("click");
        }
      });
    }

    this.#checkProfileEditImageLimit();
  }

  #checkProfileEditImageLimit() {
    if ($(`.${this.previewItem}`).length < this.INPUT_LIMIT) {
      $(`#${this.form}`).find("a.add_fields").trigger("click");
    }
  }
}

$(function () {
  const profileUserImagesUpload = new ProfileUserImagesUpload(
    "profileForm",
    "user_image_file",
    "form-table-images__delete",
    "form-table-images__item-prev"
  );
  profileUserImagesUpload.run();
});
