$(function () {
  $(".post-delete-button").on("click", function () {
    const target = $($(this).data("target"));
    const okButton = target.find(".delete-ok-btn");
    okButton.data("postId", $(this).data("postId"));
  });
  $(".delete-ok-btn").on("click", function () {
    const postId = $(this).data("postId");
    if (!postId) {
      return;
    }

    $.ajax({
      url: `/api/posts/${postId}`,
      type: "POST",
      data: { _method: "DELETE" }
    })
      .done(function (response) {
        if (response.pickup_flag) {
          location.href = location.href;
        } else {
          const postArea = $(`#post-area-${postId}`);
          if (postArea.hasClass("post")) {
            const mainArea = postArea.find(".post__main");
            mainArea.empty();
            postArea.find(".post__header").remove();
            postArea.find(".post-actions").remove();
            postArea.parents(".post-container").find(".timeline_reply_form").remove();
            const deletedText = $("<p>", {
              text: "この投稿は削除されました",
              addClass: "reply-text is-deleted"
            });
            const textParent = $("<div>", {
              class: "post-text-box js-text-parent"
            });
            const textArea = $("<div>", {
              class: "post__text"
            });
            deletedText.appendTo(textParent.appendTo(textArea.appendTo(mainArea)));
          } else {
            const mainArea = postArea.find(".reply__main");
            mainArea.empty();
            postArea.find(".reply__header").remove();
            const deletedText = $("<p>", {
              text: "リプライは削除されました",
              addClass: "reply-text is-deleted"
            });
            deletedText.appendTo(mainArea);
          }
        }
      })
      .fail(function (data) {
        if (data.status === 401) {
          location.replace("/");
        }
      });
  });
});
