import { ImagesUploadTemplate } from "./images_upload_template";

class PostImagesUpload extends ImagesUploadTemplate {
  constructor(formId, inputFieldId, previewDeleteIconClass, previewItemClass) {
    super(formId, inputFieldId, previewDeleteIconClass, previewItemClass);
  }

  run() {
    this.#checkPostEdit();
    super.run();
  }

  appendToPreview(form, result, fileInputIdNumber) {
    let parent = $("<div>", {
      class: "post-form__image"
    }).appendTo(form.find(".post-form__image-box"));
    $("<img>", {
      src: result,
      id: "previewImage"
    }).appendTo(parent.find(".post-form__image").prevObject);
    $("<button>", {
      value: "画像を削除する",
      class: "post-form__image-delete js-post-shrink-except",
      id: fileInputIdNumber
    }).appendTo(parent.find(".post-form__image").prevObject);
  }

  #checkPostEdit() {
    // 更新失敗時、_destroyがtrueもしくはバリデーションエラーのフィールドを処理
    if ($(`.${this.previewItem}`).length) {
      $(`[id^='${this.inputField}']`).each(function (_index, element) {
        let destroyFlag = $(element).next().val();
        let imageCache = $(element).prev().val();
        if (destroyFlag == "true") {
          $(element).parent().hide();
          $("#delete_cocoon_image_field").append($(element).parent());
        } else if ($(element).siblings("a.dynamic").length && !imageCache) {
          $(element).siblings("a.dynamic").trigger("click");
        }
      });
    }

    this.#checkPostEditImageLimit();
  }

  #checkPostEditImageLimit() {
    if ($(`.${this.previewItem}`).length == this.INPUT_LIMIT) {
      $(`[id^='${this.inputField}']`).closest(".post-form-actions__field").addClass("is-disabled");
    } else if ($(`.${this.previewItem}`).length < this.INPUT_LIMIT) {
      $(`#${this.form}`).find("a.add_fields").trigger("click");
    }
  }
}

$(function () {
  const postImagesUpload = new PostImagesUpload(
    "postForm",
    "post_image_file",
    "post-form__image-delete",
    "post-form__image"
  );
  postImagesUpload.run();
});
