$(function () {
  const fileInput = $("#background_image_file");
  fileInput.on("change", function (e) {
    photoPreview(e);
  });
  $(document).on("click", "#remove_image_button", function (e) {
    e.preventDefault();
    fileInput.val("");
    $("#background_image_cache").val("");
    $("#background_image_preview").next().remove();
    if ($("#user_background_image_file_cache").length) $("#user_background_image_file_cache").val("");
  });

  function photoPreview(e, file = null) {
    if (file === null) {
      file = e.target.files[0];
    }
    const reader = new FileReader();
    const preview = $("#background_image_preview");
    const previewImage = $("#preview_image");

    if (previewImage != null) {
      preview.next().remove();
    }
    reader.onload = function () {
      preview.after(
        `
          <div class="form-table__image-box">
            <div class="form-table__header-bg form-header-prev">
              <img src=${reader.result}>
              <button type="button" id="remove_image_button" class="form-header-prev__delete">画像を削除する</button>
            </div>
          </div>
        `
      );
    };

    reader.readAsDataURL(file);
  }
});
