/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-this-alias */
export class ImagesUploadTemplate {
  #previewDone = false;
  INPUT_LIMIT = 4;
  constructor(form, inputField, previewDeleteIcon, previewItem) {
    this.form = form;
    this.inputField = inputField;
    this.previewDeleteIcon = previewDeleteIcon;
    this.previewItem = previewItem;
  }

  run() {
    const inputField = this.inputField;
    const self = this;

    $(document).on("change", `[id^='${inputField}']`, function (e) {
      const inputFieldIdNumber = self.#getFileInputIdNumber(e.target.id);
      const regexp = new RegExp(inputField + "[[0-9]+]");
      if (!e.target.id.match(regexp)) {
        return;
      }
      if (e.target.files.length < 1) {
        self.#emptyFileInput($(e.target), inputFieldIdNumber);
      } else {
        self.#photoPreview(e.target);
        self.#previewDone = true;
      }
    });
    $(document).on("click", `.${this.previewDeleteIcon}`, function (e) {
      const idNumber = e.target.id;
      const fileInput = $(`[id='${inputField}[${idNumber}]']`);
      self.#emptyFileInput(fileInput, idNumber);
    });
    // cocoonの画像編集時の削除ボタン動作（既存画像）に対応
    $(document).on("cocoon:after-remove", function (e, removeField) {
      if (removeField.find(".existing").length) {
        $("#delete_cocoon_image_field").append(removeField);
      }
    });

    this.#dragAndDrop();
  }

  appendToPreview(_form, _result, _fileInputIdNumber) {
    throw new Error("Not Implemented");
  }

  // private method
  #photoPreview(fileInput) {
    fileInput = this.#imageAddedAtImageLimit(fileInput);
    const file = fileInput.files[0];
    const form = $(`#${this.form}`);
    const fileInputIdNumber = this.#getFileInputIdNumber(fileInput.id);
    const reader = new FileReader();
    const self = this;

    reader.onload = function () {
      self.appendToPreview(form, reader.result, fileInputIdNumber);
      self.#moveFileInputtedImageField();
      self.#checkPostImageLimit();
    };

    reader.readAsDataURL(file);
  }

  #dragAndDrop() {
    const ACCEPT = ["image/png", "image/jpeg", "image/gif"];
    const form = `#${this.form}`;
    const thisInputField = this.inputField;
    const self = this;

    $(document).on("dragover", form, function (e) {
      e.preventDefault();
      $(form).add("dragover");
    });
    $(document).on("dragleave", form, function (e) {
      e.preventDefault();
      $(form).remove("dragover");
    });
    $(document).on("drop", form, function (e) {
      self.#previewDone = false;
      let fileInputs = $(`[id^='${thisInputField}']`);
      const files = e.originalEvent.dataTransfer.files;
      e.preventDefault();
      $(form).remove("dragenter");
      // 拡張子チェック
      if (ACCEPT.includes(files[files.length - 1].type)) {
        self.#oldestImageDelete(fileInputs[0]);
        fileInputs = $(`[id^='${thisInputField}']`);

        if (fileInputs.length) {
          fileInputs[0].files = files;
          // safariだと↑でchangeイベントが発火してしまうので previewDone で状態管理
          if (self.#previewDone == true) {
            self.#previewDone = false;
          } else {
            self.#photoPreview(fileInputs[0]);
          }
        }
      }
    });
  }

  #getFileInputIdNumber(fileInputId) {
    return fileInputId.replace(/[^0-9]/g, "");
  }

  #oldestImageDelete(fileInput) {
    if ($(`.${this.previewItem}`).length == this.INPUT_LIMIT) {
      const fileInputIdNumber = this.#getFileInputIdNumber(fileInput.id);
      this.#emptyFileInput($(`[id='${fileInput.id}']`), fileInputIdNumber);
    }
  }

  #emptyFileInput(fileInput, fileInputIdNumber) {
    $(`#${fileInputIdNumber}`).closest(`.${this.previewItem}`).remove();
    fileInput.siblings("a.remove_fields").trigger("click");
    if ($(`.${this.previewItem}`).length == this.INPUT_LIMIT - 1) {
      $(".cocoon_image_field").closest(".post-form-actions__field").removeClass("is-disabled");
      $("a.add_fields").trigger("click");
    }
  }

  #checkPostImageLimit() {
    if ($(`.${this.previewItem}`).length == this.INPUT_LIMIT) {
      $(".cocoon_image_field").closest(".post-form-actions__field").addClass("is-disabled");
    } else if ($(`.${this.previewItem}`).length < this.INPUT_LIMIT) {
      $("a.add_fields").trigger("click");
    }
  }

  // PostImageField群の一番後ろに移動
  #moveFileInputtedImageField() {
    let fileInputtedImageField = $(".cocoon_image_field")[0];
    $("#images_field_span").before(fileInputtedImageField);
  }

  // 上限時に画像をInputFieldから追加した際の処理
  #imageAddedAtImageLimit(fileInput) {
    if ($(`.${this.previewItem}`).length == this.INPUT_LIMIT) {
      const files = fileInput.files;
      this.#oldestImageDelete(fileInput);
      fileInput = $(`[id^='${this.inputField}']`)[0];
      fileInput.files = files;
    }
    return fileInput;
  }
}
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-this-alias */
