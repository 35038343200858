//タッチデバイスか否かで取得イベントを変える
var getEvent = "ontouchstart" in window ? "orientationchange" : "resize";

//読み込み時
$(window).on("load", function () {
  const spMax = 768;

  headerFloat(spMax);
  sideNav(spMax);
  sideUsrNav(spMax);
  modal();
  customModal(spMax);
  confirmModal();
  omitText();
  formShrink(spMax);
  formShrinkClick(spMax);

});
//リサイズor回転時
$(window).on(getEvent, function () {
  //ブレイクポイントの設定
  const spMax = 768;

  headerFloat(spMax);
  sideNav(spMax);
  sideUsrNav(spMax);
  modal();
  customModal(spMax);
  confirmModal();
  omitText();
  formShrink(spMax);
  formShrinkClick(spMax);

});


// ====================
// 汎用function
// ====================
//スクロール禁止状態にする
function htmlFix() {
  var st = $(window).scrollTop() * -1;
  $("html").addClass("is-fixed");
  $("body").css("top", st);
}

//スクロール禁止状態を解除する
function htmlScroll() {
  var st = parseFloat($("body").css("top")) * -1;
  $("html").removeClass("is-fixed").scrollTop(st);
  $("body").removeAttr("style");
}

//オーバーレイ表示
function overlayOn() {
  if ($(".overlay").length < 1) {
    $("body").append("<div class=\"overlay\"></div>");
    htmlFix();
    $(".overlay").animate({
      opacity: 1
    }, 300);
  }

}

//オーバーレイ非表示
function overlayOff() {
  $(".overlay").css(
    "pointer-events", "none"
  );
  $(".overlay").fadeOut("", function () {
    $(".overlay").remove();
  });
  htmlScroll();
}


// ====================
// Header追従
// ====================
function headerFloat(spMax) {
  var $header = $(".js-header"),
    headerHeight = $header.outerHeight(),
    startPos = 0;

  if (spMax >= $(window).width()) {
    //スマホサイズ向け設定
    $(window).on("scroll", function () {
      var value = $(this).scrollTop();
      if (value > startPos && spMax >= $(window).width()) {
        $header.css("top", "-" + headerHeight + "px");
      } else {
        $header.css("top", "0");
      }
      startPos = value;
    });
  } else if (spMax < $(window).width()) {
    //PCサイズ向け設定
    $header.removeAttr("style");
  }
}

// ====================
// サイドナビ表示切り替え＆開閉
// ====================
function sideNav(spMax) {
  var $nav = $(".js-nav"),
    $openButton = $(".js-nav-open"),
    $closeButton = $(".js-nav-close");

  if (spMax >= $(window).width()) {
    //スマホサイズ向け設定

    //サイドナビを表示：htmlスクロール禁止
    $openButton.click(function () {
      $nav.addClass("is-active");
      overlayOn();
      //overlayクリックで閉じるためのclass追加
      $(".overlay").addClass("js-overlay-close-nav");
    });

    //サイドナビを非表示：htmlスクロール禁止解除
    $closeButton.click(function () {
      navClose();
    });

    //オーバーレイクリックで閉じる
    $(document).on("click", ".js-overlay-close-nav", function () {
      navClose();
    });

    function navClose() {
      $nav.removeClass("is-active");
      overlayOff();
    }

  } else if ($nav.hasClass("is-active")) {
    //PCサイズ向け設定
    $nav.removeClass("is-active");
    overlayOff();
  }
}

// ====================
// 通常モーダル
// ====================
function modal() {
  var $openModal = $(".js-modal"),
    $modal = $(".modal"),
    $modalBox = $(".js-modal-box"),
    $closeButton = $(".js-modal-close");

  //モーダルを表示：htmlスクロール禁止
  $openModal.click(function () {
    var target = $(this).attr("data-target");
    $(target).closest($modal).addClass("is-active");
    $(target).addClass("is-active").animate({
      opacity: 1
    }, 300);
    overlayOn();

    //overlayクリックで閉じるためのclass追加
    if ($(this).attr("data-overlayClose") == "true") {
      $(".overlay").addClass("js-overlay-close-modal");
    }
  });

  //モーダルを非表示：htmlスクロール禁止解除
  $closeButton.click(function () {
    modalClose();
  });

  //オーバーレイクリックで閉じる
  $(document).on("click", ".js-overlay-close-modal", function () {
    modalClose();
  });

  function modalClose() {
    $modal.removeClass("is-active");
    $modalBox.removeClass("is-active").removeAttr("style");
    overlayOff();
  }

}

// ====================
// カスタムモーダル
// ====================
function customModal(spMax) {
  var $openModal = $(".js-modal-custom"),
    $modalPc = $(".js-modal-custom-pc"),
    $modalSp = $(".js-modal-custom-sp"),
    $closeButton = $(".js-modal-custom-close");

  //リセット
  if (spMax < $(window).width()) {
    $modalSp.removeClass("is-active").removeAttr("style");
  } else if (spMax > $(window).width()) {
    $modalPc.removeClass("is-active").removeAttr("style");
  }
  //classがリセットされた時点で処理
  if (!$modalSp.hasClass("is-active") && !$modalPc.hasClass("is-active")) {
    //htmlFix状態ならブレイクポイントの時に処理をする
    if ($("html").hasClass("is-fixed")) {
      htmlScroll()
    }
    //確認モーダルが出たままなら非表示にする
    if ($(".js-confirm-message.is-active").length > 0) {
      $(".js-confirm-message").removeClass("is-active")
    }
  }

  //モーダルを表示：htmlスクロール禁止
  $openModal.click(function () {
    var target = $(this).attr("data-target");

    if ($(this).attr("data-mode") == "sp" && spMax >= $(window).width()) {
      $(target).addClass("is-active").animate({
        opacity: 1
      }, 300);
      htmlFix();
    } else if ($(this).attr("data-mode") == "pc" && spMax < $(window).width()) {
      $(target).addClass("is-active").animate({
        opacity: 1
      }, 300);
      htmlFix();
    }

  });

  //モーダルを非表示：htmlスクロール禁止解除
  $closeButton.click(function () {
    $(this).closest(".is-active").removeClass("is-active").removeAttr("style");
    htmlScroll();
  });

}



// ====================
// 確認機能つきモーダル
// ====================
function confirmModal() {
  var $parents = $(".js-confirm"),
    $messageModal = $(".js-confirm-message"),
    $closeButton = $(".js-confirm-close"),
    $no = $(".js-confirm-no"),
    $yes = $(".js-confirm-ok");

  $closeButton.click(function () {

    // テキストエリアに入力が合った場合に確認モーダルを出す、それ以外はそのまま閉じる
    if ($("trix-editor").val().length || $("#post_image_file").val()) {
      $(this).closest($parents).find($messageModal).addClass("is-active").animate({
        opacity: 1
      }, 300);
    } else {
      $messageModal.removeClass("is-active");
      $parents.removeClass("is-active").removeAttr("style");
      htmlScroll();

      //親modalがいる場合は削除する
      if ($(".modal.is-active").length > 0) {
        $(".modal").removeClass("is-active");
      }
      //overlayが入れば削除する
      if ($(".overlay").length > 0) {
        overlayOff();
      }
    }

  });


  $no.click(function () {
    $messageModal.removeClass("is-active");
  });

  $yes.click(function () {
    $("#post_body").val("");
    $(".post-form__image-delete").trigger("click");

    $messageModal.removeClass("is-active");
    $parents.removeClass("is-active").removeAttr("style");
    //親modalがいる場合は削除する
    if ($(".modal.is-active").length > 0) {
      $(".modal").removeClass("is-active");
    }
    //overlayが入れば削除する
    if ($(".overlay").length > 0) {
      overlayOff();
    }
  })
}


// ====================
// モーダル 利用規約同意
// ====================
function openTermsModal() {
  $(window).on('load', function () {
    // .js-terms-modalがあればクリックさせる
    if ($('.js-terms-modal').length) {
      $('.js-terms-modal').trigger('click');
    }

  });
}
openTermsModal();


// ====================
// モーダル 下書き保存確認
// ====================
function openSaveModal() {
  $(window).on('load', function () {
    // .js-save-modalがあればクリックさせる
    if ($('.js-save-modal').length) {
      $('.js-save-modal').trigger('click');
    }

  });
}
openSaveModal();

// ====================
// モーダル 利用規約同意チェックボックス制御
// ====================
$(function () {
  // 同意するにチェックでボタン活性
  $(document).on('change', '.js-check', function () {
    var button = $('.js-submit');

    if (button.hasClass('is-disabled')) {
      button.removeClass('is-disabled').prop('disabled', false);
    } else {
      button.addClass('is-disabled').prop('disabled', true);
    }
  });
});



// ====================
// スムーズスクロール  js-scroll
// ====================
$(function () {

  $.easing.quart = function (x, t, b, c, d) {
    return -c * ((t = t / d - 1) * t * t * t - 1) + b;
  };

  //URLのハッシュ値を取得
  var urlHash = location.hash;
  //ハッシュ値があればページ内スクロール

  if (urlHash) {
    //スクロールを0に戻す
    $('body,html').stop().animate({
      scrollTop: 0
    }, 1200);
    setTimeout(function () {
      //ロード時の処理を待ち、時間差でスクロール実行
      scrollToAnchor(urlHash);
    }, 100);
  }

  scroll();

});

function scroll() {
  $('.js-scroll').on("click", function () {
    var speed = 400,
      href = $(this).attr("href"),
      target = $(href == "#" || href == "" ? 'html' : href);
    scrollToAnchor(target);

    // targetにjs-scroll-focusクラスを持つテキストエリアがある場合、フォーカスを当てる
    var targetTextarea = target.find('textarea');
    if ($(targetTextarea).hasClass("js-scroll-focus")) {
      $(targetTextarea).first().focus();
    }
    return false;
  });

}

function scrollToAnchor(hash) {
  var target = $(hash),
    header = $(".header").height(),
    position = target.offset().top,
    position = position < header ? position : position - header - 20; //固定ヘッダー分+α　を適宜追加する

  $('html,body').stop().animate({
    scrollTop: position
  }, 1200, 'quart');
}

// ====================
// ページ上部へ戻る
// ====================
$(function () {
  var t = 300; //数値は調整可能
  //初期表示
  if ($(window).scrollTop() > t) {
    $(".js-page-top").show();
  } else {
    $(".js-page-top").hide();
  }
  $(window).scroll(function () {
    if ($(window).scrollTop() > t) {
      $(".js-page-top").fadeIn("fast");
    } else {
      $(".js-page-top").fadeOut("fast");
    }
  });
});


// ====================
// アコーディオンパネル トグル
// ====================
$(function () {
  $('.js-toggle-panel').hide();

  $('.js-toggle-trigger').each(function () {

    var $initialText = $(this).text();
    var $txtSet = $(this).children(".js-toggle-txt");

    $(this).on('click', function () {

      if ($(this).hasClass('is-opened')) {
        $(this).removeClass('is-opened');
        $('.js-toggle-panel').slideUp();
        $('.js-toggle-text').text($initialText);
      } else {
        $(this).addClass('is-opened');
        $('.js-toggle-panel').slideDown();
        $('.js-toggle-text').text('閉じる');
      }
    })
  })
});

// ====================
// アコーディオンパネル トグル（複数トグルがある場合）
// ====================
$(function () {
  $('.js-common-toggle-panel').hide();

  $(".js-common-toggle-trigger").click(function () {
    if ($(this).hasClass("is-opened")) {
      //閉じる処理
      $(this).parents(".js-common-toggle").find(".js-common-toggle-panel").slideUp(300);
      $(this).removeClass("is-opened");
    } else {
      //開く処理
      $(this).parents(".js-common-toggle").find(".js-common-toggle-panel").slideDown(300);
      $(this).addClass("is-opened");
    }
  });
});

// ====================
// タッチデバイスでのhoverスタイル解除
// ====================
$(function () {
  var touch = 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  if (touch) {
    try {
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si];
        if (!styleSheet.rules) continue;

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue;

          if (styleSheet.rules[ri].selectorText.match(':hover')) {
            styleSheet.deleteRule(ri);
          }
        }
      }
    } catch (ex) {}
  }
});



// ====================
// カテゴリー選択
// ====================
$(function () {
  if ($(".js-select").length > 0) {
    //一覧を準備
    $(".js-select").each(function () {
      var group = $(this).find(".select-value");
      //optionを取得して一覧にする

      $(this).find("option").each(function (index, element) {
        var v = $(this).text();

        $(group).append("<li class=\"select-value__list\">" + v + "</li>")
      });

      //select初期セット
      $(this).find(".select__selected").text($(this).find('option:first-child').text());
    });

    //クリックでで選択肢表示
    $(".js-show-select").click(function () {
      var p = $(this).parents(".js-select");
      //すでに開いているものは閉じておく
      $(".select-value").hide();
      if ($(p).hasClass("is-opened")) {
        $(p).removeClass("is-opened");
        $(p).find(".select-value").fadeOut("fast");
      } else {
        $(p).addClass("is-opened");
        $(p).find(".select-value").fadeIn("fast");
      }
    });

    //選択肢クリックで値を反映
    $(".select-value__list").click(function () {
      var t = $(this).text(),
        p = $(this).parents(".js-select");
      $(p).find(".select__selected").text(t)
      $(p).find("select").val($(p).find('option').filter(function () {
        return $(this).text() === t
      }).val());

      //選択肢を閉じる
      $(p).removeClass("is-opened");
      $(p).find(".select-value").hide();
    });

    //領域外をクリックで選択肢を閉じる
    $(document).click(function (event) {
      if (!$(event.target).closest(".js-select.is-opened").length) {
        $(".select-value").hide();
        $(".js-select.is-opened").removeClass("is-opened");
      }
    });

  }
});

// ====================
// 3点リーダーメニュー
// ====================
$(function () {
  var $panel = $('.js-select-menu-panel');
  if ($('.js-select-menu').length > 0) {
    $('.js-select-menu').each(function () {

      // 初期メニュー非表示
      $panel.hide();

      $(this).on('click', function () {
        $(this).children($panel).fadeIn();
        $(this).addClass('is-opened');
      })
    });

  }
  //領域外をクリックで選択肢を閉じる
  $(document).click(function (event) {
    if (!$(event.target).closest(".js-select-menu.is-opened").length) {
      $panel.hide();
      $(".js-select-menu.is-opened").removeClass("is-opened");
    }
  });
});


// ====================
// 投稿・リプライ本文 続きを見る
// 4行目まで表示、5行目以降非表示
// 続きを見るで全文表示、「閉じる」なし
// ====================
function omitText() {
  var lineNum = 4; //表示する行数


  // テキストが表示制限の行数を超えたら発動
  $('.js-text-parent').each(function () {
    var $text = $(this).find('.js-text'); //対象のテキスト
    var $more = $(this).find('.js-text-more'); //続きを読むボタン
    var $bg = $(this).find('.js-text-bg'); //テキストぼかし白背景

    var textHeight = $text.height(); // 本来のテキストの高さ
    var lineHeight = parseFloat($text.css('line-height')); //cssで指定しているline-height
    var textNewHeight = lineHeight * lineNum; //指定した行数までのテキストの高さ


    //初期状態セット
    // 指定行数分のline-heightを超える、且つis-openedクラスを持たない場合に実行
    if ($text.height() > textNewHeight && !$(this).hasClass('is-opened')) {
      $(this).css({
        height: textNewHeight,
      }).removeClass("is-exception");
      $more.show();
      $bg.show();
    } else {
      // 指定した行数以下のテキストなら続きを読むは表示しない
      $(this).css({
        height: textHeight,
      });
      $more.hide();
      $bg.hide();
      if (!$(this).hasClass('is-opened')) {
        $(this).addClass('is-exception');
      }
    }

  });

  //テキスト領域クリックで全文表示
  $('.js-text').on('click', function (e) {
    var $more = $(this).siblings('.js-text-more'); //続きを読むボタン
    var $bg = $(this).siblings('.js-text-bg'); //テキストぼかし白背景

    var textHeight = $(this).height(); // 本来のテキストの高さ
    var lineHeight = parseFloat($(this).css('line-height')); //cssで指定しているline-height
    var textNewHeight = lineHeight * lineNum; //指定した行数までのテキストの高さ

    var $box = $(this).closest('.js-text-parent');
    if ($box.hasClass('is-opened')) {
      $box.removeClass('is-opened').css({
        height: textNewHeight,
      });
      $more.show();
      $bg.show();
    } else if ($box.hasClass('is-exception') == false) {
      $box.addClass('is-opened').css({
        height: textHeight,
      });
      $more.hide();
      $bg.hide();
    }
    e.stopImmediatePropagation();
  });
}

// ====================
// リプライ 4件以上の場合、最新3件表示（リストの後ろから3件のみ表示）
// 3件以下の場合、全件表示でボタンは非表示
// ====================
$(function () {
  var box = $(".js-reply-box"); //リストを囲うボックス
  var btn = $(".js-reply-more"); //もっと見るボタン
  var item = $(".js-reply-list").children('li'); //リスト
  var target = $(".js-reply-list li:nth-last-child(-n+3)"); //リスト後ろから3件（最新3件）

  //初期表示：2件目以降非表示
  box.each(function () {
    // 親投稿に紐づくリプライ総数
    var num = $(this).find(".js-reply-list").children('li').length;

    // もっと見るボタンにリプライ数を反映
    $(this).find(btn).text(`…他の会話をもっと見る (全${num}件)`);

    // リプライボタンへリプライ数を反映
    $(this).prev().find($('.js-reply-totalNum')).html(num);

    // 4件以上の場合、最新3件のみを表示
    if (num >= 4) {
      // 全件非表示
      $(item).hide();
      //
      $(target).show();
    } else {
      //3件以下の場合：ボタン削除
      $(this).find(btn).remove();
    }

    //もっと見るボタンクリックで全件表示
    $(this).find(btn).on("click", function () {
      // リプライリスト最後の3件以外
      var target = $(".js-reply-list li:not(:nth-last-child(-n+3))");

      if ($(this).hasClass("is-opened")) {
        $(this).closest(box).find(target).slideUp();
        $(this).text(`…他の会話をもっと見る (全${num}件)`).removeClass("is-opened");
      } else {
        $(this).closest(box).find("li").slideDown();
        $(this).text(`閉じる (全${num}件)`).addClass("is-opened");
        omitText();
      }
    });
  });
});


// ====================
// 投稿・リプライフォームを初期収納
// クリックでis-closed除去
// PC投稿フォーム、PCSPリプライフォーム
// ====================
function formShrink(spMax) {
  var all = $(".js-post-shrink, .js-post-shrink-pc, .js-post-shrink-sp"),
    $pc = $(".js-post-shrink-pc"),
    $sp = $(".js-post-shrink-sp");

  //クリック時（開く時）
  all.click(function () {
    $(this).removeClass("is-closed");
  });

  //初期・リサイズ時
  if (spMax >= $(window).width()) {
    //スマホサイズ向け設定
    $pc.removeClass("is-closed");
    $sp.each(function () {
      formShrinkCheck($(this));
    });

  } else if (spMax < $(window).width()) {
    //PCサイズ向け設定
    $sp.removeClass("is-closed");
    $pc.each(function () {
      formShrinkCheck($(this));
    });
  }
}

function formShrinkClick(spMax) {
  var all = $(".js-post-shrink, .js-post-shrink-pc, .js-post-shrink-sp"),
    $normal = $(".js-post-shrink"),
    $pc = $(".js-post-shrink-pc"),
    $sp = $(".js-post-shrink-sp");

  //エリア外クリック時（閉じる時）
  $(document).on('click', function (e) {
    //除外箇所
    if ($(e.target).hasClass("js-post-shrink-except") ||
      $(e.target).closest(".js-post-shrink-except").length) {
      return;

    } else if (!$(e.target).closest(all).length) {
      // ターゲット要素の外側をクリックした時

      $normal.each(function () {
        formShrinkCheck($(this));
      });

      if (spMax >= $(window).width()) {
        //スマホサイズ向け設定
        $sp.each(function () {
          formShrinkCheck($(this));
        });

      } else if (spMax < $(window).width()) {
        //PCサイズ向け設定
        $pc.each(function () {
          formShrinkCheck($(this));
        });
      }

    } else {
      // ターゲット要素をクリックした時の操作
    }
  });
}

//テキスト入力をチェックし表示可否
function formShrinkCheck(t) {
  var t = t,
    textarea = t.find(".js-post-shrink-textarea");

  if (textarea.val().length) {
    // 入力がある時
    t.removeClass("is-closed");
  } else {
    // 入力なし
    t.addClass("is-closed");
  }
}

// ====================
// テキストエリアの高さ自動調整
// PC・SP
// js-flex-field テキストエリア親
// js-flex-field-keep 高さ確保用の空白要素
// js-flex-field-textarea テキストエリア
// ====================
function flexTextarea(el) {
  const target = el.querySelector('.js-flex-field-keep')

  el.querySelector('.js-flex-field-textarea').addEventListener('input', e => {
    target.textContent = e.target.value + '\u200b'
  })
}

$(function () {
  document.querySelectorAll('.js-flex-field').forEach(flexTextarea);
});

// ====================
// リアクション いいね
// ====================
$(function () {
  if ($('.js-like-icon').hasClass('is-clicked')) {
    $('.js-like-icon').removeClass('is-clicked');
  }

  $(".js-button-like").click(function () {
    var $icon = $(this).children('.js-like-icon');

    if ($icon.hasClass('is-active')) {
      $icon.removeClass('is-active is-clicked');
    } else {
      $icon.addClass('is-active is-clicked');
    }
  });
});



// ====================
// チェックボックス ラジオボタンの挙動（複数選択不可）
// TL検索
// ====================
$(function () {
  var $inputs = $('.js-search-check');

  //チェックボックスを複数選択できないようにする
  $inputs.on('click', function () {
    if ($(this).prop('checked')) {
      $inputs.prop('checked', false);
      $(this).prop('checked', true);
    }
  });
});

// ====================
// テキスト交換ボタン
// ====================
// ホバーで要素交換（フォロー、フォロー中テキスト交換等）
$(function () {
  var $parent = $('.js-exchange-parent');
  var $beforeCont = $('.js-exchange-before');
  var $afterCont = $('.js-exchange-after');
  $afterCont.hide();

  $parent.hover(function () {
      $(this).find($beforeCont).hide();
      $(this).find($afterCont).show();
    },
    function () {
      $(this).find($beforeCont).show();
      $(this).find($afterCont).hide();
    })
});

// ====================
// グループ 参加者選択 チェックボックス制御
// ====================
$(function () {
  // チェックでユーザー情報スタイル切り替え
  $(".js-member-check").on("change", function () {
    var $item = $(".js-member-item");
    var $target = $(this).closest($item).find($(".js-member-bio"));

    if ($target.hasClass("is-disabled")) {
      $target.removeClass("is-disabled");
    } else {
      $target.addClass("is-disabled");
    }
  });
});

// ====================
// フォーム パスワード表示
// ====================
$(function () {
  $('.js-toggle-password').on('click', function () {
    $(this).toggleClass('is-show');

    var $input = $(this).prev();
    if ($input.attr('type') == 'text') {
      $input.attr('type', 'password');
    } else {
      $input.attr('type', 'text');
    }
  })
})

// ====================
// サイドユーザーナビ表示切り替え＆開閉
// ====================
function sideUsrNav(spMax) {
  var $nav = $(".js-usr-nav"),
  $openButton = $(".js-usr-nav-open"),
  $closeButton = $(".js-usr-nav-close");

  if (spMax >= $(window).width()) {
    //スマホサイズ向け設定

    //サイドナビを表示：htmlスクロール禁止
    $openButton.click(function () {
      $nav.addClass("is-active");
      overlayOn();
      //overlayクリックで閉じるためのclass追加
      $(".overlay").addClass("js-overlay-close-nav");
    });

    //サイドナビを非表示：htmlスクロール禁止解除
    $closeButton.click(function () {
      navClose();
    });

    //オーバーレイクリックで閉じる
    $(document).on("click", ".js-overlay-close-nav", function () {
      navClose();
    });

    function navClose() {
      $nav.removeClass("is-active");
      overlayOff();
    }
  } else if (spMax < $(window).width()) {
    //PCサイズ向け設定

    $openButton.click(function () {
      let t = $(".js-usr-panel");
  
      if ($(this).hasClass("is-opened")) {
        //バルーンを表示している状態の場合は非表示
        $(this).removeClass("is-opened");
        $(t).removeClass("is-opened");
      } else {
        //すでに開いているバルーンは閉じる
        $(".js-usr-nav-open.is-opened").removeClass("is-opened");
        $(".js-usr-panel.is-opened").removeClass("is-opened");
  
        //対象を表示
        $(this).addClass("is-opened");
        $(t).addClass("is-opened");
      }
    });
  }
}
