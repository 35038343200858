// ====================
// 投稿画像ギャラリーモーダル（modaal）
// ====================
$(function() {
  $(".js-gallery").modaal({
    type: 'image',
    overlay_close:true,//モーダル背景クリック時に閉じるか
    before_open:function(){// モーダルが開く前に行う動作
      $('html').css('overflow-y','hidden');/*縦スクロールバーを出さない*/
    },
    after_close:function(){// モーダルが閉じた後に行う動作
      $('html').css('overflow-y','scroll');/*縦スクロールバーを出す*/
    }
  });
});

// ====================
// 投稿 添付動画モーダル（modaal）
// ====================
$(function() {
  $(".js-video-trigger").modaal({
    before_close: function () {
      $(".modaal-content-container").find("video")[0].pause();
    }
  });
});
