$(function () {
  // block
  $(document).on("click", ".user-block-button", function () {
    blockAction($(this));
  });

  // unBlock
  $(document).on("click", ".user-unblock-button", function () {
    unBlockAction($(this));
  });

  $(document).on("click", ".unblock-ok-button", function () {
    const userId = $(this).data("userId");
    const isPost = $(this).data("isPost");
    if (!userId && !isPost) {
      return;
    }

    $.ajax({
      url: `/api/blocks/${userId}`,
      type: "POST",
      data: { _method: "DELETE", is_post: isPost }
    })
      .done(function (response) {
        if (isPost) {
          const unBlockArea = $(`.user-unblock-button[data-user-id=${userId}]`).parent();
          unBlockArea.html(response);
          $(`.user-block-button[data-user-id=${userId}]`).parent().prev().show();
        } else {
          location.reload();
        }
      })
      .fail(function () {
        location.replace("/timelines");
      });
  });
});

function unBlockAction(e) {
  const target = $(e.data("target"));
  const okButton = target.find(".unblock-ok-button");
  okButton.data("userId", e.data("userId"));
  okButton.data("isPost", e.data("isPost"));
}

function blockAction(e) {
  const userId = e.data("userId");
  const isPost = e.data("isPost");
  if (!userId && !isPost) {
    return;
  }

  $.ajax({
    url: "/api/blocks",
    type: "POST",
    data: { _method: "POST", id: userId, is_post: isPost }
  })
    .done(function (response) {
      if (isPost) {
        const blockArea = $(`.user-block-button[data-user-id=${userId}]`).parent();
        blockArea.html(response);

        $("<button>", {
          text: "フォローする",
          class: "user-follow-button",
          "data-user-id": userId,
          "data-is-post": isPost
        }).replaceAll($(`.user-unfollow-button[data-user-id=${userId}]`));
        $(`.user-unblock-button[data-user-id=${userId}]`).parent().prev().hide();
        modal();
      } else {
        location.reload();
      }
    })
    .fail(function () {
      location.replace("/timelines");
    });
}

// ====================
// 通常モーダル
// ====================
function modal() {
  var $openModal = $(".js-modal"),
    $modal = $(".modal"),
    $modalBox = $(".js-modal-box"),
    $closeButton = $(".js-modal-close"),
    st = 0;

  //モーダルを表示：htmlスクロール禁止
  $openModal.click(function () {
    st = $(window).scrollTop();
    var target = $(this).attr("data-target");
    $(target).closest($modal).addClass("is-active");
    $(target).addClass("is-active").animate(
      {
        opacity: 1
      },
      300
    );
    overlayOn();

    //overlayクリックで閉じるためのclass追加
    if ($(this).attr("data-overlayClose") == "true") {
      $(".overlay").addClass("js-overlay-close-modal");
    }
  });

  //モーダルを非表示：htmlスクロール禁止解除
  $closeButton.click(function () {
    modalClose();
  });

  //オーバーレイクリックで閉じる
  $(document).on("click", ".js-overlay-close-modal", function () {
    modalClose();
  });

  function modalClose() {
    $modal.removeClass("is-active");
    $modalBox.removeClass("is-active").removeAttr("style");
    overlayOff(st);
  }
}

// ====================
// 汎用function
// ====================
//スクロール禁止状態にする
function htmlFix() {
  $("html").addClass("is-fixed");
}

//スクロール禁止状態を解除する
function htmlScroll(st) {
  $("html").removeClass("is-fixed").scrollTop(st);
  $("body").removeAttr("style");
}

//オーバーレイ表示
function overlayOn() {
  if ($(".overlay").length < 1) {
    $("body").append('<div class="overlay"></div>');
    htmlFix();
    $(".overlay").animate(
      {
        opacity: 1
      },
      300
    );
  }
}

//オーバーレイ非表示
function overlayOff(st) {
  $(".overlay").css("pointer-events", "none");
  $(".overlay").fadeOut("", function () {
    $(".overlay").remove();
  });
  htmlScroll(st);
}
