// ====================
// SP投稿モーダル用js
// （TL以外の画面からの遷移に対応）
// ====================
//
$(window).on('load', function() {
  var entryModal = location.hash;

  if (entryModal == "#postForm") {
    $('.js-post-trigger').trigger('click');
  }

})
