$(function () {
  const fileInput = $("#community_icon_image_file");
  fileInput.on("change", function (e) {
    photoPreview(e);
  });
  $(document).on("click", "#remove_image_button", function (e) {
    e.preventDefault();
    fileInput.val("");
    $("#community_icon_image_cache").val("");
    $("#community_icon_preview").next().remove();
  });

  function photoPreview(e, file = null) {
    if (file === null) {
      file = e.target.files[0];
    }
    const reader = new FileReader();
    const preview = $("#community_icon_preview");
    const previewImage = $(".preview_image");

    if (previewImage != null) {
      preview.next().remove();
    }
    reader.onload = function () {
      preview.after(
        `
          <div class="form-table__icon-image">
            <div class="form-avatar-prev">
              <div class="avatar">
                <img src=${reader.result} class="avatar__img">
              </div>
              <button type="button" id="remove_image_button" class="form-avatar-prev__delete">画像を削除する</button>
            </div>
          </div>
        `
      );
    };

    reader.readAsDataURL(file);
  }
});
