import { AttachmentUploadTemplate } from "./attachment_upload_template";

class ReplyAttachmentUpload extends AttachmentUploadTemplate {
  constructor(form, inputField, previewDeleteIcon) {
    super(form, inputField, previewDeleteIcon);
  }

  appendToPreview(file, form, fileInputId) {
    $("<div>", {
      text: file.name,
      class: "post-form__attachment-item",
      id: `item_${fileInputId}`
    }).appendTo(form.find(`#${fileInputId}_preview`));
    $("<button>", {
      "aria-label": "削除する",
      class: "post-form__attachment-delete"
    }).appendTo(form.find(`#item_${fileInputId}`));
  }
}

$(function () {
  const replyAttachmentUpload = new ReplyAttachmentUpload(
    "timeline_reply_form",
    "reply-form-attachment",
    "post-form__attachment-delete"
  );
  replyAttachmentUpload.run();
});
