const SP_MAX = 768;
//タッチデバイスか否かで取得イベントを変える
var getEvent = "ontouchstart" in window ? "orientationchange" : "resize";

//読み込み時
$(window).on("load", function () {
  initCheckConfirmModal();
  addConditionFormShrink();
});

//リサイズor回転時
$(window).on(getEvent, function () {
  initCheckConfirmModal();
  addConditionFormShrink();
});

$(function () {
  $(document).on("click", "a:not(.cocoon-link)[target!='_blank']", function (e) {
    const click_link = $(this).attr("href");
    if (
      checkFormField() &&
      SP_MAX < $(window).width() &&
      location.href.match(/posts\/[0-9]+\/edit\/?$|timelines/) &&
      $("#draft-submit-button").length
    ) {
      e.preventDefault();
      $("#draft_confirm_button").trigger("click");
      modalButtonEventSet(click_link);
    }
  });
});

function hasValue() {
  const checkForm = ["#input_field_url", ".post-textarea", "#post_title"];
  let isValue = false;

  $.each(checkForm, function (key, form) {
    $(form).each(function () {
      if ($(this).val()) {
        isValue = true;
        return false;
      }
    });

    if (isValue) {
      return false;
    }
  });

  return isValue;
}

function modalButtonEventSet(click_link) {
  const confirmSelector = $("#confirm-message");
  const deleteButton = confirmSelector.find(".button-delete");
  const actionButton = confirmSelector.find(".button-action");

  deleteButton.off("click.location");
  deleteButton.on("click.location", function () {
    location.href = click_link;
  });

  actionButton.off("click.draftSubmit");
  actionButton.on("click.draftSubmit", function () {
    $("#draft-submit-button").val(click_link);
    $("#draft-submit-button").trigger("click");
  });
}

function checkFormField() {
  let image = $(".post-form__image").length;
  let attachment = $(".post-form__attachment-item").length;
  let category = $(".select__selected").text() && $(".select__selected").text() != "選択してください";

  return image || attachment || hasValue() || category;
}

function addConditionFormShrink() {
  $(document).on("click", function (e) {
    const pc = $(".js-post-shrink-pc");
    if ($(e.target).hasClass("js-post-shrink-except") || $(e.target).closest(".js-post-shrink-except").length) {
      return;
    } else if (!$(e.target).closest(pc).length) {
      pc.each(function () {
        if (checkFormField() || SP_MAX >= $(window).width()) {
          $(this).removeClass("is-closed");
        } else {
          $(this).addClass("is-closed");
        }
      });
    }
  });
}

function initCheckConfirmModal() {
  const pc = $(".js-post-shrink-pc");
  pc.each(function () {
    if (checkFormField() || SP_MAX >= $(window).width()) {
      $(this).removeClass("is-closed");
    } else {
      $(this).addClass("is-closed");
    }
  });
}
