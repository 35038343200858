$(document).on("click", ".js-submit", function () {
  if (!$("#invitation_agree_term").length) {
    $.ajax({
      url: "/api/agree_terms/",
      dataType: "json",
      type: "POST",
      success: function () {
        // モーダルクローズ
        $(".modal").removeClass("is-active");
        $(".js-modal-box").removeClass("is-active").removeAttr("style");
        $(".overlay").fadeOut("", function () {
          $(".overlay").remove();
        });
        var st = parseFloat($("body").css("top")) * -1;
        $("html").removeClass("is-fixed").scrollTop(st);
        $("body").removeAttr("style");
      },
      error: function (event) {
        if (event.status === 401) {
          location.replace("/");
        }
      }
    });
  }
});
