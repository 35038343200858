$(function () {
  $("#post-submit-button").on("click", function () {
    if ($("#input_field_url")[0].checkValidity()) {
      $(this).addClass("is-disabled");
      $("#js-loader").fadeIn();
    } else {
      postFormScroll();
    }
  });
  $("#draft-submit-button").on("click", function () {
    if ($("#input_field_url")[0].checkValidity()) {
      $(this).addClass("is-disabled");
      $("#js-loader").fadeIn();
    } else {
      postFormScroll();
    }
  });
  $(document).on("ajax:success", "#postForm", function (event, _xhr, _settings) {
    const response = event.detail[2].response;
    if (isValidJson(response)) {
      if (event.detail[0].location) {
        location.href = event.detail[0].location;
      } else {
        location.href = "/timelines";
      }
      return;
    }

    $("#post-submit-button").removeClass("is-disabled");
    $("#draft-submit-button").removeClass("is-disabled");
    $("#js-loader").fadeOut();
    $(".post-message-box").html(response);
    postFormScroll();
  });

  $(document).on("ajax:error", "#postForm", function (event, _xhr, _settings) {
    if (event.detail[2].status === 401) {
      location.replace("/");
    }
  });
});

function isValidJson(value) {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
}

function postFormScroll() {
  let target = $(".post-message-box"),
    header = $(".header").height(),
    position = target.offset().top;

  position = position < header ? position : position - header - 20; //固定ヘッダー分+α　を適宜追加する
  $("html,body").stop().animate({ scrollTop: position }, 1200, "quart");
}
