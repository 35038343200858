import { AttachmentUploadTemplate } from "./attachment_upload_template";

class ProfileUserAttachmentUpload extends AttachmentUploadTemplate {
  constructor(form, inputField, previewDeleteIcon) {
    super(form, inputField, previewDeleteIcon);
  }

  appendToPreview(file, form, fileInputId) {
    $("<div>", {
      text: file.name,
      class: "form-table-attachment-item post-form__attachment-item",
      id: `item_${fileInputId}`
    }).appendTo(form.find(`#${fileInputId}_preview`));
    $("<button>", {
      "aria-label": "削除する",
      class: "form-table-attachment__delete"
    }).appendTo(form.find(`#item_${fileInputId}`));
  }
}

$(function () {
  const profileUserAttachmentUpload = new ProfileUserAttachmentUpload(
    "profileForm",
    "form-table-attachment",
    "form-table-attachment__delete"
  );
  profileUserAttachmentUpload.run();
});
