import $ from "jquery";

$(function () {
  if ($(".reply-list").length > 0) {
    $(".reply-totalNum").html($(".reply-list").children("li").length);
  }

  $(".reply-submit-button").on("click", function () {
    if ($("#input_field_url")[0].checkValidity()) {
      $(this).addClass("is-disabled");
      $("#js-loader").fadeIn();
    }
  });

  $(document).on("ajax:success", "#timeline_reply_form", function (event, _xhr, _settings) {
    const response = event.detail[2].response;
    if (isValidJson(response)) {
      location.href = location.href;
      return;
    }
    $("#js-loader").fadeOut();
    $(this).find(".reply-submit-button").removeClass("is-disabled");
    $(this).find(".reply-error-box").html(response);
  });

  $(document).on("ajax:error", "#timeline_reply_form", function (event, _xhr, _settings) {
    if (event.detail[2].status === 401) {
      location.replace("/");
    } else {
      location.replace("/timelines");
    }
  });
});

function isValidJson(value) {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
}
