import { AttachmentUploadTemplate } from "./attachment_upload_template";

class PostAttachmentUpload extends AttachmentUploadTemplate {
  constructor(form, inputField, previewDeleteIcon) {
    super(form, inputField, previewDeleteIcon);
  }

  run() {
    this.#checkPostEditAttachments();
    super.run();
  }

  appendToPreview(file, form, fileInputId) {
    $("<div>", {
      text: file.name,
      class: "post-form__attachment-item",
      id: `item_${fileInputId}`
    }).appendTo(form.find(`#${fileInputId}_preview`));
    $("<button>", {
      "aria-label": "削除する",
      class: "post-form__attachment-delete js-post-shrink-except"
    }).appendTo(form.find(`#item_${fileInputId}`));
  }

  #checkPostEditAttachments() {
    let attachments = $(".post-form-actions__field");
    attachments.each(function (index, element) {
      element = $(element);

      if (index == 0 || element.data("removeFlag")) {
        return true;
      } else if (element.data("presentFlag")) {
        element.addClass("is-disabled");
      }
    });
  }
}

$(function () {
  const postAttachmentUpload = new PostAttachmentUpload(
    "postForm",
    "post-form-attachment",
    "post-form__attachment-delete"
  );
  postAttachmentUpload.run();
});
