$(function () {
  let observer = new IntersectionObserver(function (entries, _observer) {
    for (const entry of entries) {
      const target = entry.target;
      if (entry.intersectionRatio > 0 && target.className === "post-info__id") {
        const post_id = target.textContent.split("：")[1];
        $.ajax({
          type: "POST",
          url: "/api/browsing_logs",
          data: {
            post_id: post_id
          }
        });
      }
    }
  });

  $("body")
    .find("*")
    .each(function (index, element) {
      observer.observe(element);
    });
});
