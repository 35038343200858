$(function () {
  $(".user-like-button").on("click", function () {
    const userId = $(this).data("user_id");
    const likeButton = $(`#like-count-${userId}`).parent();
    likeButton.addClass("is-disabled").prop("disabled", true);
    $.ajax({
      url: `/api/user_reactions/${userId}`,
      type: "PATCH"
    })
      .done(function (response) {
        $(`#like-count-${response.user_id}`).text(response.user_reactions_count);
        likeButton.removeClass("is-disabled").prop("disabled", false);
      })
      .fail(function (data) {
        if (data.status === 401) {
          location.replace("/");
        }
      });
  });
});
