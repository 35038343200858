$(function () {
  const fileInput = $("#message_icon_image_file");
  fileInput.on("change", function (e) {
    if (e.target.files.length) photoPreview(e);
  });
  $(document).on("click", "#remove_image_button", function (e) {
    e.preventDefault();
    fileInput.val("");
    $("#message_icon_image_file_cache").val("");
    $("#message_icon_preview").next().remove();
  });

  function photoPreview(e, file = null) {
    if (file === null) {
      file = e.target.files[0];
    }
    const reader = new FileReader();
    const preview = $("#message_icon_preview");
    const previewImage = $(".preview_image");

    if (previewImage != null) {
      preview.next().remove();
    }
    reader.onload = function () {
      preview.after(
        `
          <div class="form-table__icon-image">
            <div class="form-avatar-prev">
              <div class="avatar">
                <img src=${reader.result} class="avatar__img">
              </div>
              <button type="button" id="remove_image_button" class="form-avatar-prev__delete">画像を削除する</button>
            </div>
          </div>
        `
      );
    };

    if (file.size > 2000000) {
      if (!$("#js_message_icon_image").length) {
        $(".form-table__icon-image").after(
          `<p class="form-error" id="js_message_icon_image">
            アイコン画像ファイルを2MBバイト以下のサイズにしてください
          </p>`
        );
      }
    } else {
      $("#js_message_icon_image").remove();
      reader.readAsDataURL(file);
    }
  }
});
