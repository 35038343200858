$(function () {
  $(".draft-delete-button").on("click", function () {
    const target = $($(this).data("target"));
    const okButton = target.find(".draft-delete-ok");
    okButton.data("postId", $(this).data("postId"));
  });
  $(".draft-delete-ok").on("click", function () {
    const postId = $(this).data("postId");
    if (!postId) {
      return;
    }

    $.ajax({
      url: `/api/draft_posts/${postId}`,
      type: "POST",
      data: { _method: "DELETE" }
    }).done(function () {
      location.href = "/posts/drafts";
    });
  });
});

$(function () {
  let index = $("#post_category_ids").find(":selected").val();
  //select初期セット
  if (index) {
    $(this)
      .find(".select__selected")
      .text($(this).find(`option:nth-child(${index})`).text());
  }
});
