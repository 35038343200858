window.addEventListener("pageshow", function (event) {
  // ブラウザバック時(バックフォワード)のリアクション表示に対応
  if (event.persisted) {
    const reactionCount = getReactionCount();
    updateReaction("create_ids", reactionCount, true);
    updateReaction("delete_ids", reactionCount, false);
  }
});

$(function () {
  browserBackForEdge();
  removeReactionStorage();

  $(".post-like-button").on("click", function () {
    const postId = $(this).data("post_id");
    const likeButton = $(`#like-count-${postId}`).parent();
    likeButton.addClass("is-disabled").prop("disabled", true);

    $.ajax({
      url: `/api/reactions/${postId}`,
      type: "PATCH"
    })
      .done(function (response) {
        $(`#like-count-${response.post_id}`).text(response.reactions_count);

        if ($(`#like-count-${postId}`).prev().hasClass("is-active")) {
          removeSessionStorage("delete_ids", postId);
          addSessionStorage("create_ids", postId);
        } else {
          removeSessionStorage("create_ids", postId);
          addSessionStorage("delete_ids", postId);
        }
        setReactionCount(postId, response.reactions_count);
        likeButton.removeClass("is-disabled").prop("disabled", false);
      })
      .fail(function (data) {
        if (data.status === 401) {
          location.replace("/");
        } else {
          location.replace("/timelines");
        }
      });
  });
});

/**
 * 特定の投稿のリアクション数とリアクションボタンの表示を変更する
 * @param {string} key ","で区切られた投稿IDの文字列
 * @param {map} reactionCount keyに投稿ID、valueにリアクション数を持つmap
 * @param {boolean} shouldAddClass リアクションボタンの表示を変更するclassを追加すべきか
 */
function updateReaction(key, reactionCount, shouldAddClass) {
  let ids = sessionStorage.getItem(key);
  if (!ids) return;

  ids = ids.split(",");
  ids.forEach((id) => {
    const likeCount = $(`#like-count-${id}`);
    if (!likeCount.length) return true;

    likeCount.text(reactionCount.get(id));
    if (shouldAddClass) {
      likeCount.prev().addClass("is-active");
    } else {
      likeCount.prev().removeClass("is-active");
      likeCount.prev().removeClass("is-clicked");
    }
  });
}

/**
 * 特定のセッションストレージに数値を追加する。
 * すでに値が存在する場合は、","を数値の手前に付与して既存の値と結合する
 * "1,2,3"
 * @param {string} key 数値を追加するセッションストレージのキー
 * @param {number} id 追加する数値
 */
function addSessionStorage(key, id) {
  let ids = sessionStorage.getItem([key]);
  if (ids) {
    sessionStorage.setItem(key, ids + `,${id}`);
  } else {
    sessionStorage.setItem(key, id);
  }
}

/**
 * ","で区切られた数値を持つ文字列から特定の数値を削除する
 * @param {string} key 取得するセッションストレージのキー
 * @param {number} id 文字列から削除する数値
 */
function removeSessionStorage(key, id) {
  let ids = sessionStorage.getItem(key);
  if (!ids) return;

  ids = ids.split(",");
  const index = ids.indexOf(String(id));
  if (index == -1) return;

  ids.splice(index, 1);
  sessionStorage.setItem(key, ids.join(","));
}

/**
 * セッションストレージのReactionCountにキーと値を追加する
 * セッションストレージは文字列しか保存できないため変換を行う
 * @param {number} postId ReactionCountに追加するキー
 * @param {number} count ReactionCountに追加する値
 */
function setReactionCount(postId, count) {
  const reactionCount = getReactionCount();
  reactionCount.set(String(postId), count);
  ids = [...reactionCount.entries()].flat();
  sessionStorage.setItem("reaction_count", ids.join(","));
}

/**
 * ","で区切られた数値を偶数個持つ文字列をmapに変換する
 * "1,5,13,52" -> {"1": "5", "13": "52"}
 * @returns {map} {投稿ID: リアクション数} のmapを返す
 */
function getReactionCount() {
  const reactionCount = sessionStorage.getItem("reaction_count");
  if (reactionCount) return new Map(twoSplice(reactionCount.split(",")));

  return new Map();
}

/**
 * listが空になるまで手前から値を２つ取り出して、responseに追加することで二次元配列を作成する
 * @param {array} list [1, 6, 12, 1] 数値の一次元配列
 * @param {array} response 返り値、初期値として空の配列
 * @returns {array} [[1, 6],[12, 1]]の二次元配列を返す
 */
function twoSplice(list, response = []) {
  response.push(list.splice(0, 2));
  if (list.length) twoSplice(list, response);
  return response;
}

function isBackForward() {
  if (!window.performance.getEntriesByType("navigation")[0]) return false;

  const type = window.performance.getEntriesByType("navigation")[0].type;
  if (type == "back_forward") {
    return true;
  } else {
    return false;
  }
}

function removeReactionStorage() {
  const timelines_path = new RegExp("/timelines");
  if (timelines_path.test(location.href) && !isBackForward()) {
    sessionStorage.removeItem("create_ids");
    sessionStorage.removeItem("delete_ids");
    sessionStorage.removeItem("reaction_count");
  }
}

/**
 * ブラウザバック時(バックフォワード)のリアクション表示に対応
 * Edgeの場合、pageshowが動作しないせずにreadyが動作するため
 */
function browserBackForEdge() {
  const reactionCount = getReactionCount();
  if (reactionCount.size && isBackForward()) {
    updateReaction("create_ids", reactionCount, true);
    updateReaction("delete_ids", reactionCount, false);
  }
}
