import { ImagesUploadTemplate } from "./images_upload_template";

class ReplyImagesUpload extends ImagesUploadTemplate {
  constructor(formId, inputFieldId, previewDeleteIconClass, previewItemClass) {
    super(formId, inputFieldId, previewDeleteIconClass, previewItemClass);
  }

  run() {
    this.#addReplyImageField();
    super.run();
  }

  appendToPreview(form, result, fileInputIdNumber) {
    let parent = $("<div>", {
      class: "post-form__image"
    }).appendTo(form.find(".post-form__image-box"));
    $("<img>", {
      src: result,
      id: "previewImage"
    }).appendTo(parent.find(".post-form__image").prevObject);
    $("<button>", {
      value: "画像を削除する",
      class: "post-form__image-delete",
      id: fileInputIdNumber
    }).appendTo(parent.find(".post-form__image").prevObject);
  }

  #addReplyImageField() {
    if ($(`.${this.previewItem}`).length < this.INPUT_LIMIT) {
      $(`#${this.form}`).find("a.add_fields").trigger("click");
    }
  }
}

$(function () {
  const replyImagesUpload = new ReplyImagesUpload(
    "timeline_reply_form",
    "reply_image_file",
    "post-form__image-delete",
    "post-form__image"
  );
  replyImagesUpload.run();
});
