$(() => {
  // 参加
  $(".group-user-join-button").on("click", function () {
    $(this).addClass("is-disabled");
    const groupId = $(this).data("groupId");
    if (!groupId) {
      return;
    }

    $.ajax({
      url: "/api/community_users",
      type: "POST",
      data: { _method: "POST", id: groupId }
    }).done(function () {
      location.href = location.href;
    });
  });

  // 退出
  $(".group-leave-button").on("click", function () {
    const target = $($(this).data("target"));
    const okButton = target.find(".leave-ok-button");
    okButton.data("groupId", $(this).data("groupId"));
  });

  $(".leave-ok-button").on("click", function () {
    $(this).addClass("is-disabled");
    const groupId = $(this).data("groupId");
    if (!groupId) {
      return;
    }

    $.ajax({
      url: `/api/community_users/${groupId}`,
      type: "POST",
      data: { _method: "DELETE" }
    }).done(() => {
      location.href = location.href;
    });
  });
});
