$(function () {
  // メッセージ作成 -> メンバー追加
  $(document).on("click", ".form-table-member__button", function (e) {
    e.preventDefault();

    let path = $(this).attr("href");
    let data = {};
    let title = $("#group_title").val();
    let description = $("#group_description").val();
    let ids = getMemberIds();

    const reader = new FileReader();
    reader.onload = function () {
      data = {
        message: {
          title: title,
          icon_image_file_data_uri: reader.result,
          description: description,
          member_ids: ids
        },
        is_new_message: true
      };
      message_session_ajax(path, data);
    };

    if ($("#message_icon_image_file")[0].files.length) {
      reader.readAsDataURL($("#message_icon_image_file")[0].files[0]);
    } else {
      let icon_image_file_data_uri = $("#message_icon_image_file_data_uri").val();
      let icon_image_file_cache = $("#message_icon_image_file_cache").val();
      data = {
        message: {
          title: title,
          icon_image_file_data_uri: icon_image_file_data_uri,
          icon_image_file_cache: icon_image_file_cache,
          description: description,
          member_ids: ids
        },
        is_new_message: true
      };
      message_session_ajax(path, data);
    }
  });

  // メンバー追加 -> 検索
  $(document).on("submit", "#member_search_form", function (e) {
    e.preventDefault();

    let path = $("#member_search_form").attr("action") + "?" + $("#member_search_form").serialize();
    let data = {};
    let ids = getMemberIds();
    data = { message: { member_ids: ids } };

    message_session_ajax(path, data);
  });

  // メンバー追加 -> ページネーション
  $(document).on("click", ".pagination__box a", function (e) {
    const messages_members_path = new RegExp("/messages/members");
    if (!messages_members_path.test(location.href)) return;

    e.preventDefault();
    let path = $(this).attr("href");
    let data = {};
    let ids = getMemberIds();
    data = { message: { member_ids: ids } };

    message_session_ajax(path, data);
  });

  // メンバー追加 -> メッセージ開設
  $(document).on("submit", "#new_message_button", function (e) {
    e.preventDefault();

    let path = $("#new_message_button").attr("action");
    let data = {};
    let ids = getMemberIds();
    data = { message: { member_ids: ids } };

    message_session_ajax(path, data);
  });

  // メッセージ作成中止
  $(document).on("click", "a[target!='_blank']", function (e) {
    const messages_path = new RegExp("/messages");
    if (!messages_path.test(location.href)) return;

    if (!$(this).parent().is("[class*='nav'], [class*='header']")) return;

    e.preventDefault();
    let path = $(this).attr("href");
    $.ajax({
      url: "/api/message_sessions",
      type: "POST",
      data: { _method: "DELETE" }
    })
      .done(function () {
        location.href = path;
      })
      .fail(function () {
        location.href = "/timelines";
      });
  });
});

function message_session_ajax(path, data) {
  $.ajax({
    url: "/api/message_sessions",
    type: "POST",
    data: data
  })
    .done(function () {
      location.href = path;
    })
    .fail(function () {
      location.href = "/timelines";
    });
}

function getMemberIds() {
  let ids = $.map($(".member-ids"), function (element) {
    if (element.value !== "") return element.value;
  });
  if (!ids.length) ids = false;
  return ids;
}
