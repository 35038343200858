$(function () {
  // 参加
  $(document).on("click", ".message-member-join-button", function () {
    const memberId = $(this).data("memberId");
    if (!memberId) {
      return;
    }

    let messageLeaveButton = $("<button>", {
      class: "button-user-status is-active js-modal js-exchange-parent message-member-leave-button",
      "data-target": "#agree-message",
      "data-overlayclose": true,
      "data-member-id": memberId
    }).replaceAll($(this));
    $("<span>", {
      class: "button-user-status__text js-exchange-before",
      text: "参加中"
    }).appendTo(messageLeaveButton);
    $("<span>", {
      class: "button_user_status__text js-exchange-after",
      text: "削除"
    }).insertAfter(messageLeaveButton.find(".js-exchange-before"));

    let hidden = $(".list-user__button-box").find(`#member_id_${memberId}`);
    hidden.val(memberId);

    $(".js-modal").off("click");
    $(".js-modal-close").off("click");
    $(document).off("click", ".js-overlay-close-modal");
    dispatchEvent(new Event("load"));
    jsExchangeText();
  });

  // 退出
  $(document).on("click", ".message-member-leave-button", function () {
    const target = $($(this).data("target"));
    const okButton = target.find(".message-member-leave-ok-button");
    okButton.data("memberId", $(this).data("memberId"));
  });

  $(document).on("click", ".message-member-leave-ok-button", function () {
    const memberId = $(this).data("memberId");
    if (!memberId) {
      return;
    }

    let parent = $("body").find(`.message-member-leave-button[data-member-id='${memberId}']`);
    let messageJoinButton = $("<button>", {
      class: "button-user-status message-member-join-button",
      "data-member-id": memberId
    }).replaceAll(parent);
    $("<span>", {
      class: "button-user-status__text",
      text: "追加する"
    }).appendTo(messageJoinButton);

    let hidden = $(".list-user__button-box").find(`#member_id_${memberId}`);
    hidden.val(null);
  });
});

function jsExchangeText() {
  let $parent = $(".js-exchange-parent");
  let $beforeCont = $(".js-exchange-before");
  let $afterCont = $(".js-exchange-after");
  $afterCont.hide();

  $parent.hover(
    function () {
      $(this).find($beforeCont).hide();
      $(this).find($afterCont).show();
    },
    function () {
      $(this).find($beforeCont).show();
      $(this).find($afterCont).hide();
    }
  );
}
