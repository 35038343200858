/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-this-alias */
export class AttachmentUploadTemplate {
  #attachmentsDone = { audio: false, application: false, video: false };
  constructor(form, inputField, previewDeleteIcon) {
    this.form = form;
    this.inputField = inputField;
    this.previewDeleteIcon = previewDeleteIcon;
  }

  run() {
    const inputField = this.inputField;
    const self = this;

    $(document).on("change", `.${inputField}`, function () {
      const fileInput = $(this);
      if (fileInput[0].className !== inputField) {
        return;
      }
      if (fileInput[0].files.length < 1) {
        self.#emptyFileInput(fileInput);
      } else {
        self.#attachmentPreview(fileInput);
        const type = self.#getFileType(fileInput[0].files[0]);
        self.#attachmentsDone[type] = true;
      }
    });
    $(document).on("click", `.${this.previewDeleteIcon}`, function () {
      const click_id = $(this).parent()[0].id.replace(/item_/, "");
      const fileInput = $(`#${click_id}`);
      self.#emptyFileInput(fileInput);
    });
    this.#dragAndDrop();
  }

  appendToPreview(_file, _form, _fileInputId) {
    throw new Error("Not Implemented");
  }

  // private method
  #attachmentPreview(fileInput) {
    const file = fileInput[0].files[0];
    const form = $(`#${this.form}`);
    const fileInputId = fileInput[0].id;

    if ($(`#item_${fileInputId}`) != null) {
      form.find(`#item_${fileInputId}`).remove();
    }

    fileInput.closest(".post-form-actions__field").addClass("is-disabled");
    form.find("#" + `${fileInput[0].id}_remove`).val(false);
    this.appendToPreview(file, form, fileInputId);
  }

  #dragAndDrop() {
    const ACCEPT = ["audio/mpeg", "application/pdf", "video/mp4", "video/quicktime"];
    const form = `#${this.form}`;
    const self = this;

    $(document).on("dragover", form, function (e) {
      e.preventDefault();
      $(form).add("dragover");
    });
    $(document).on("dragleave", form, function (e) {
      e.preventDefault();
      $(form).remove("dragover");
    });
    $(document).on("drop", form, function (e) {
      const files = e.originalEvent.dataTransfer.files;
      const type = self.#getFileType(files[0]);
      const fileInput = $(`#file_field_${type}`);
      e.preventDefault();
      $(form).remove("dragenter");

      if (ACCEPT.includes(files[0].type)) {
        self.#attachmentsDone[type] = false;
        self.#emptyFileInput(fileInput);
        fileInput[0].files = files;
        // safariだと↑でchangeイベントが発火してしまうので attachmentsDone で状態管理
        if (self.#attachmentsDone[type] == true) {
          self.#attachmentsDone[type] = false;
        } else {
          self.#attachmentPreview(fileInput);
        }
      }
    });
  }

  #emptyFileInput(fileInput) {
    fileInput.val(null);
    const form = $(`#${this.form}`);
    form.find("#" + `${fileInput[0].id}_cache`).val(null);
    form.find("#" + `${fileInput[0].id}_remove`).val(true);
    form.find(`#item_${fileInput[0].id}`).remove();
    fileInput.closest(".post-form-actions__field").removeClass("is-disabled");
  }

  #getFileType(file) {
    let end = file.type.indexOf("/");
    return file.type.substr(0, end);
  }
}
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-this-alias */
