import Rails from "@rails/ujs";

Rails.start();

import "modaal/dist/js/modaal.min";
import "@rails/actiontext";
import "trix";
import "@nathanvda/cocoon";

import "./sp_post_form_close_confirm_modal";
import "./common";
import "./modal";
import "./post-modal";
import "./agree_term";

import "./post_images_upload";
import "./profile_image_upload";
import "./post";
import "./reply";
import "./reply_images_upload";
import "./post_delete";
import "./pc_post_form_breakaway_prevention_modal";
import "./hide_anchor";
import "./reaction";
import "./drag_and_drop_disabled";
import "./post_attachment_upload";
import "./reply_attachment_upload";
import "./post_edit_select";
import "./browsing_log";
import "./draft_post_delete";
import "./follow";
import "./background_images_upload";
import "./block";
import "./community_icon_image_upload";
import "./user_reaction";
import "./group_user";
import "./profile_user_attachment_upload";
import "./profile_user_images_upload";
import "./action_text_mention";
import "./message_join_button";
import "./message_icon_image_upload";
import "./message_session";
import "./toggle_category_checkboxes";
