import Trix from "trix";
import Tribute from "tributejs";

$(function () {
  if ($("trix-editor").length) initializeTribute();
});

async function initializeTribute() {
  let trixEditor = $("trix-editor");
  let users = await fetchUsers();
  let tribute = new Tribute({
    values: users,
    noMatchTemplate: "該当するユーザーが見つかりません"
  });

  tribute.attach(trixEditor);
  Object.getPrototypeOf(tribute.range).pasteHtml = pasteHtml.bind(trixEditor[0]);
  trixEditor.on("tribute-replaced", (e) => replaced(e));
  trixEditor.on("keydown", (e) => {
    if (e.metaKey) {
      let keyEvent = new KeyboardEvent("keydown", { keyCode: 27 });
      trixEditor[0].dispatchEvent(keyEvent);
    }
  });
}

function fetchUsers() {
  return new Promise((resolve, reject) => {
    $.ajax({ url: "/users/mentions.json" })
      .done((response) => {
        resolve(response);
      })
      .fail(() => {
        reject([]);
      });
  });
}

function replaced(e) {
  let detail = e.detail;

  if (typeof detail.item === "undefined") {
    let content = "@" + detail.context.mentionText;
    e.target.editor.insertString(content);
  } else {
    let content = detail.item.original.content;
    let attachment = new Trix.Attachment({
      content: content
    });
    e.target.editor.insertAttachment(attachment);
    e.target.editor.insertString(" ");
  }
}

function pasteHtml(html, startPos, endPos) {
  let position = this.editor.getPosition();
  let numberOfCharactersEntered = endPos - startPos;
  this.editor.setSelectedRange([position - numberOfCharactersEntered, position]);
  this.editor.deleteInDirection("backward");
}
