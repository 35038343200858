const SP_MAX = 768;
//タッチデバイスか否かで取得イベントを変える
var getEvent = "ontouchstart" in window ? "orientationchange" : "resize";

//読み込み時
$(window).on("load", function () {
  addConditionCloseConfirmModal();
  openPostFormModal();
});

//リサイズor回転時
$(window).on(getEvent, function () {
  addConditionCloseConfirmModal();
  openPostFormModal();
});

$(function () {
  let $postForm = $("#postForm"),
    $messageModal = $(".js-confirm-message"),
    $closeButton = $(".js-confirm-close"),
    $yes = $(".js-confirm-ok"),
    $no = $(".js-confirm-no");

  // sp：投稿フォーム、下書き一覧押下
  $(document).on("click", "a[href='/posts/drafts']", function (e) {
    if (checkFormField() && SP_MAX >= $(window).width() && location.href.match(/timelines/)) {
      e.preventDefault();
      $closeButton.trigger("click");
      switchingLocation($(this), $yes);
    }
  });
  // sp：編集フォーム、リンク押下
  $(document).on("click", "a:not(.cocoon-link)[target!='_blank']", function (e) {
    if (checkFormField() && SP_MAX >= $(window).width() && location.href.match(/posts\/[0-9]+\/edit\/?$/)) {
      e.preventDefault();
      $postForm.find($messageModal).addClass("is-active").animate({ opacity: 1 }, 300);
      switchingLocation($(this), $yes);
    }
  });

  $no.on("click.deleteLocation", function () {
    $yes.off("click.location");
  });
});

function switchingLocation($this, $yes) {
  $yes.on("click.location", function () {
    location.href = $this.attr("href");
  });
}

function addConditionCloseConfirmModal() {
  let $parents = $(".js-confirm"),
    $messageModal = $(".js-confirm-message"),
    $closeButton = $(".js-confirm-close"),
    $yes = $(".js-confirm-ok");

  $closeButton.on("click", function (e) {
    // テキストエリアに入力が合った場合に確認モーダルを出す、それ以外はそのまま閉じる
    if (checkFormField()) {
      // ↓ common.jsで登録される「.js-confirm-close」のclickイベントを停止する
      e.stopImmediatePropagation();
      $(this).closest($parents).find($messageModal).addClass("is-active").animate({ opacity: 1 }, 300);
    }
  });

  $yes.on("click", function () {
    $("#post_title").val("");
    $("#post_field_url").val("");
    $(".select__selected").text($("#post_category_ids").find("option:nth-child(1)").text());
    $("#post_category_ids").val("");
    $(".post-form__attachment-delete").trigger("click");
  });
}

function hasValue() {
  const checkForm = ["#input_field_url", "#post_title", ".post-textarea"];
  let isValue = false;

  $.each(checkForm, function (key, form) {
    $(form).each(function () {
      if ($(this).val()) {
        isValue = true;
        return false;
      }
    });

    if (isValue) {
      return false;
    }
  });

  return isValue;
}

function checkFormField() {
  let image = $(".post-form__image").length;
  let attachment = $(".post-form__attachment-item").length;
  let category = $(".select__selected").text() && $(".select__selected").text() != "選択してください";

  return image || attachment || hasValue() || category;
}

function openPostFormModal() {
  if (checkFormField() && SP_MAX >= $(window).width() && location.href.match(/timelines/)) {
    $("#postForm").addClass("is-active").animate({ opacity: 1 }, 300);
  }
}
